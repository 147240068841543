import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Hydroo1 from "/src/components/img/hydroo/1"
import Hydroo2 from "/src/components/img/hydroo/2"


export default () => (
  <Layout>
    <SEO title="Prameny a minerální vody" />
    <h1>Prameny a minerální vody</h1>
    <p><strong>Pramen</strong> je soustředěn&yacute; či rozpt&yacute;len&yacute; v&yacute;tok podzemn&iacute; vody na zemsk&yacute; povrch. Z&aacute;kladn&iacute; veličinou při jejich měřen&iacute; je <strong>vydatnost pramene</strong>, kter&aacute; vyjadřuje, kolik vody z&nbsp;pramene vyteče za jednotku času (obvykle l.s<sup>-1</sup>, př&iacute;padně m<sup>3</sup>.s<sup>-1</sup>).</p>
    <p><strong>Miner&aacute;ln&iacute; vody</strong> se od obyčejn&yacute;ch podzemn&iacute;ch vod li&scaron;&iacute; množstv&iacute;m a druhem rozpu&scaron;těn&yacute;ch <strong>miner&aacute;ln&iacute;ch l&aacute;tek, plynů</strong> i <strong>teplotou</strong>.</p>
    <hr /><h2>Typy pramenů</h2>
    <p>Prameny lze rozli&scaron;ovat dle <strong>trv&aacute;n&iacute; v&yacute;ronu vody</strong>, a to na:</p>
    <ul>
    <li>st&aacute;l&eacute;,</li>
    <li>občasn&eacute; (tj. periodick&eacute;),</li>
    <li>epizodick&eacute;.</li>
    </ul>
    <p>Lze je t&eacute;ž rozdělovat dle <strong>změn vydatnost&iacute;</strong> na prameny:</p>
    <ul>
    <li>s&nbsp;velmi vyrovnanou vydatnost&iacute;,</li>
    <li>s&nbsp;průměrnou vydatnost&iacute;,</li>
    <li>s&nbsp;nevyrovnanou vydatnost&iacute;.</li>
    </ul>
    <p>Podle <strong>způsobu v&yacute;stupu vody z&nbsp;podzem&iacute;</strong> lze pak prameny rozli&scaron;it na:</p>
    <ul>
    <li>sestupn&eacute; (vyt&eacute;k&aacute;n&iacute; podzemn&iacute; vody při sestupn&eacute;m pohybu z&nbsp;oblasti infiltrace do oblasti vyvěr&aacute;n&iacute;; nejtypičtěj&scaron;&iacute; př&iacute;klad v ČR),</li>
    <li>v&yacute;stupn&eacute; (vyt&eacute;k&aacute;n&iacute; podzemn&iacute; vody při vzestupn&eacute;m pohybu vody vlivem tlaku vody ve vodonosn&eacute; vrstvě; art&eacute;zsk&aacute; voda, vztlak plynu, vliv zv&yacute;&scaron;en&eacute; teploty u term&aacute;ln&iacute;ch pramenů)</li>
    <li>přet&eacute;kaj&iacute;c&iacute; (tj. přelivn&eacute;; voda se hromad&iacute; v podzemn&iacute; z&aacute;sobě, přes jej&iacute;ž okraj přet&eacute;k&aacute; na povrch).</li>
    </ul>
    <p>St&aacute;lost a nest&aacute;lost pramene může signalizovat hloubku oběhu vody pramene a určujeme ji dle poměru maxim&aacute;ln&iacute; a minim&aacute;ln&iacute; vydatnosti pramene (Qmax/Qmin). Pak můžeme mluvit o <strong>stupn&iacute;ch rozkol&iacute;sanosti</strong>, dle nichž stanovujeme n&aacute;sleduj&iacute;c&iacute; typy pramenů:</p>
    <ul>
    <li>velmi st&aacute;l&eacute; (poměr Qmax/Qmin = 1 : 1),</li>
    <li>st&aacute;l&eacute; (1 : 1 až 1 : 2),</li>
    <li>nest&aacute;l&eacute; (1 : 2 až 1 : 10),</li>
    <li>velmi nest&aacute;l&eacute; (1 : 10 až 1 : 30),</li>
    <li>celkově nest&aacute;l&eacute; (1 : 30 až 1 : &infin;).</li>
    </ul>
    <p>Z&nbsp;poměrů Qmax a Qmin a jin&yacute;ch charakteristik lze pak na z&aacute;kladě <strong>stupňů spolehlivosti, st&aacute;losti, variability vydatnosti</strong> (v = (Qmax-Qmin)/Qa * 100) a dal&scaron;&iacute;ch vyčlenit dal&scaron;&iacute; typy pramenů.</p>
    <p>Prameny lze vyčlenit i dle teploty, obsahu miner&aacute;ln&iacute;ch l&aacute;tek (viz kapitola n&iacute;že) a dal&scaron;&iacute;ch krit&eacute;ri&iacute;.</p>
    <hr /><h2>Vznik miner&aacute;ln&iacute;ch vod</h2>
    <p><strong>Vodn&iacute; složka</strong> miner&aacute;ln&iacute;ch vod je na na&scaron;em &uacute;zem&iacute; vět&scaron;inou <strong>pevninsk&eacute;ho původu</strong> a jde o vad&oacute;zn&iacute; vodu. V&nbsp;men&scaron;&iacute; m&iacute;ře může m&iacute;t i juveniln&iacute; původ. Vodn&iacute; složka může m&iacute;t v&scaron;ak tak&eacute; <strong>původ mořsk&yacute;</strong> &ndash; jde pak o uzavřen&eacute; polohy b&yacute;val&yacute;ch moř&iacute; či slan&yacute;ch jezer a mluv&iacute;me o tzv. <strong>reliktn&iacute;ch miner&aacute;ln&iacute;ch vod&aacute;ch</strong>.</p>
    <p>Podle <strong>chemick&eacute; složky</strong> miner&aacute;ln&iacute;ch vod rozli&scaron;ujeme miner&aacute;ln&iacute; vody <strong>petrogenn&iacute;</strong> (dle horninov&eacute;ho prostřed&iacute;), <strong>marinogenn&iacute;</strong> (dle moře) a <strong>sm&iacute;&scaron;en&eacute;</strong>.</p>
    <hr /><h2>Klasifikace miner&aacute;ln&iacute;ch a term&aacute;ln&iacute;ch vod</h2>
    <p>Miner&aacute;ln&iacute; vody lze dělit dle <strong>hydrologick&yacute;ch struktur</strong>. Hydrologick&aacute; struktura je geologicky-tektonicky a hydrologicky vymezen&aacute; jednotka, na kterou je dan&aacute; miner&aacute;ln&iacute; voda v&aacute;zan&aacute;.</p>
    <p>Lze je klasifikovat dle <strong>vydatnosti pramenů</strong>, viz kapitola v&yacute;&scaron;e.</p>
    <p>Dal&scaron;&iacute; možnost&iacute; je vymezen&iacute; podle <strong>teploty vody</strong>, a to n&aacute;sledovně:</p>
    <ul>
    <li><strong>vody studen&eacute;</strong> (&lt; 15 &deg;C)</li>
    <li><strong>vody term&aacute;ln&iacute;</strong> (15 &ndash; 40 &deg;C)
    <ul>
    <li>teplice (15 &ndash; 20 &deg;C)</li>
    <li>velmi n&iacute;zkoterm&aacute;ln&iacute; (21 &ndash; 30 &deg;C)</li>
    <li>n&iacute;zko term&aacute;ln&iacute; (31 &ndash; 40 &deg;C)</li>
    </ul>
    </li>
    <li><strong>vody hyperterm&aacute;ln&iacute; </strong>(41 &ndash; 100 &deg;C)
    <ul>
    <li>středně term&aacute;ln&iacute; (41 &ndash; 70 &deg;C)</li>
    <li>vysoko term&aacute;ln&iacute; (71 &ndash; 100 &deg;C)</li>
    </ul>
    </li>
    <li><strong>vody přehř&aacute;t&eacute;</strong> (&gt; 100 &deg;C)</li>
    </ul>
    <p>Dal&scaron;&iacute; je dělen&iacute; podle <strong>chemick&eacute;ho složen&iacute;</strong>. Vět&scaron;ina klasifikac&iacute; vych&aacute;z&iacute; pouze z&nbsp;principu převl&aacute;daj&iacute;ch iontů či hypotetick&yacute;ch sol&iacute;. Tyto klasifikace b&yacute;vaj&iacute; velmi rozs&aacute;hl&eacute;, jako jedno z&nbsp;krit&eacute;ri&iacute; se např&iacute;klad použ&iacute;v&aacute; zastoupen&iacute; 6 hlavn&iacute;ch iontů miner&aacute;ln&iacute;ch vod, mezi něž patř&iacute; Cl<sup>-</sup>, SO<sub>4</sub><sup>2-</sup>, HCO<sub>3</sub><sup>-</sup>, Na<sup>+</sup>, Ca<sub>2</sub><sup>+</sup>, Mg<sub>2</sub><sup>+</sup>.</p>
    <p>Podle <strong>obsahu rozpu&scaron;těn&yacute;ch pevn&yacute;ch l&aacute;tek</strong> rozli&scaron;ujeme tyto miner&aacute;ln&iacute; vody:</p>
    <ul>
    <li>velmi slabě mineralizovan&eacute; = do 1 g.l<sup>-1</sup></li>
    <li>slabě mineralizovan&eacute; = 1 &ndash; 5 g.l<sup>-1</sup></li>
    <li>středně mineralizovan&eacute; = 5 &ndash; 10 g.l<sup>-1</sup></li>
    <li>silně mineralizovan&eacute; = 10 &ndash; 35 g.l<sup>-1</sup></li>
    <li>velmi silně mineralizovan&eacute; = 35 &ndash; 50 g.l<sup>-1</sup></li>
    <li>solanky (rosoly, ropy) = nad 50 g.l<sup>-1</sup></li>
    </ul>
    <p>D&aacute;le vymezujeme miner&aacute;ln&iacute; vody i dle <strong>obsahu plynu</strong>, a to předev&scaron;&iacute;m CO<sub>2</sub> a H<sub>2</sub>S:</p>
    <ul>
    <li><strong>kyselky</strong> (vody uhličit&eacute;, alespoň 1 g.l<sup>-1 </sup>CO<sub>2</sub>)</li>
    <li><strong>s&iacute;rn&eacute; vody </strong>(alespoň 1 mg.l<sup>-1</sup> H<sub>2</sub>S)</li>
    </ul>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Prameny a minerální vody</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/podpovrchova-voda/"><button className="inv">&larr; Půdní a podzemní voda</button></Link>
    <Link to="/hydrologie/vodni-nadrze/"><button className="inv">Vodní nádrže &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
